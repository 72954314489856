<template>
  <section class="staff-dropdown">
    <v-select
      v-model="staffChoosed"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="staffOptions"
      :reduce="val => val.value"
      input-id="staff-task"
      autocomplete="new-staff"
      @input="loadData"
      v-if="!['Sales Staff'].includes($store.state.user.role)"
    />
    <!-- Toggler -->
    <b-link
      class="resync-task-button align-items-center justify-content-center"
      @click="reloadData"
    >
      <feather-icon
        icon="RefreshCcwIcon"
        size="15"
      />
    </b-link>
  </section>
</template>

<script>
import {
  BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from "@vue/composition-api";
import { mainDB } from "@/database/mainDB";
import store from "@/store";
import router from '@/router'

export default {
  components: {
    BLink,
    vSelect
  },
  props: {
  },
  setup(props, context) {
    const vm = context.root
    const taskSync = store.state.taskSync
    const staffChoosed = ref(null)
    const staffOptions = ref([{label: vm.$i18n.t('Please choose staff'), value: null}])

    const defaultSetup = async () => {
      const staffs = await mainDB.staffs.orderBy("shortName").toArray();
      staffs.forEach(function (staff) {
        staffOptions.value.push({label: staff.shortName, value: staff.id})
      })
      staffChoosed.value = taskSync.staffId.value
    }
    defaultSetup()

    const loadData = () => {
      taskSync.staffId = staffChoosed
      store.dispatch("taskSync/update", taskSync);
      router.push('tasks').catch(() => {})
    }

    const reloadData = () => {
      taskSync.sessionTime = vm.getCurrentTimestamp()
      store.dispatch("taskSync/update", taskSync);
      router.push('tasks').catch(() => {})
    }

    return {
      staffOptions,
      staffChoosed,
      defaultSetup,
      loadData,
      reloadData
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.staff-dropdown {
  min-width: 200px;
}
</style>